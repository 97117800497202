.shopping-list {
  margin-top: 50px;
}

.shopping-list-title {
  text-align: center;
  margin-bottom: 60px !important;
}

.recipe-scale {
  width: 60%;
}

.recipe-list {
  margin-bottom: 40px;
}

.recipe-list > .MuiPaper-root > .MuiList-root > .MuiListItem-container > .MuiListItem-root > .MuiListItemIcon-root {
  width: 30%;
}

.name {
  text-transform: capitalize;
}