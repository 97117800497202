.recipe-creator {
  margin-top: 100px;
}

.recipe-sections {
}

.recipe-add-section {
  content: '+';
  font-size: 18px;
}

.recipe-section-add-step {
  visibility: hidden;
  color: green;
}

.recipe-section-add-step:after {
  content: '+';
  font-size: 28px;
  visibility: visible;
}

.recipe-step {
}

.recipe-step::placeholder{
  content: 'test';
}

.save-recipe-fab {
  position: relative !important;
  bottom: 10px;
  left: 35%;
  z-index: 999;
}
