.recipe-display {
  margin-top: 50px;
}

.recipe-display-paper {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;
}

.controls {
  text-align: center;
}

.recipe-title {
  text-align: center;
  margin-bottom: 20px !important;
}

.recipe-section-title {
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

.recipe-section-ingredients-title, .recipe-section-instructions-title {
  margin-top: 18px;
  margin-bottom: 8px;
  margin-left: -12px;
}

.add-to-cart{
  float: right;
}